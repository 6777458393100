export default {
    data () {
        return {
            venueList: [],
            mealList: [],
            accommodationList: [],
            otherList: [],
            loadType: '',
            rightTxt: '询价',
            isCanEdit: true
        }
    },
    computed: {
        isCanAdd () {
            return !!(this.isCanEdit && this.rightTxt)
        },
    },
    methods: {
        async getItems() {
            this.venueList = []
            this.mealList = []
            this.accommodationList = []
            this.otherList = []
            let params = {
                proposalId: this.$SmartStorage.get('proposalId'),
                orderId: this.$SmartStorage.get('orderId'),
            }
            let res = await this.$service.QueryOrderItemAndSupplierDetail(params)
            if (res && res.success) {
                this.loadType = '';
                this.rqdata = res.content || [];
                if (this.rqdata.length) {
                    this.rqdata.forEach((ele, index) => {
                        if ([1, 5, -2,-1].includes(ele.status)) {
                            this.rightTxt = "";
                        }
                        if (['ipsen'].includes(this.$cookie.get("tenant")) && ele.status == 999) {
                            this.isCanEdit = false
                        }
                        ele.key = new Date().getTime() + index
                        switch (ele.itemType) {
                            case 1:
                                this.venueList.push(ele)
                                break
                            case 2:
                                this.mealList.push(ele)
                                break
                            case 3:
                                this.accommodationList.push(ele)
                                break
                            case 4:
                                this.otherList.push(ele)
                                break
                        }
                    });
                }
            }
        },
        async getAddDemandBtn () {
            let miceInfo = this.$SmartStorage.get("eventData")
            let params = {
                collection:"cfg-ivenue-service",
                useCaching:false,
                cachingKey:"",
                filter:{
                    tenantCode: this.$cookie.get("tenant"),
                    "isOrder": true,
                    eventType:{"$in":[ miceInfo?.typeDictTxt || "*","*"]},
                    eventPlace:{"$in":[miceInfo?.eventPlace || "*","*"]},
                    "eventCity.city":{"$in":[miceInfo?.cityDictVal || "*","*"]},
                }
            }
            let res = await this.$service.getsettings(params)
            if (res && res.success) {
                this.demandTypeList = res.content.channels
            }
        },
        StartInquiry () {
            if (this.rightTxt != "询价") {
                return
            }
            let total = this.venueList.length + this.mealList.length + this.accommodationList.length + this.otherList.length
            if (total < 1) {
                this.$toast("请至少创建一条需求");
                return;
            }
            let venueList = this.$SmartStorage.get('venueList')
            if (venueList.length < 1) {
                this.$toast("请添加至少一个酒店");
                return;
            }
            this.$dialog.confirm({
                title: '提示',
                message: '请确保您所有的需求都已经添加完成，一旦提交不可再增加或修改需求，请仔细确认！',
            }).then(async () => {
                let params = {
                    proposalId: this.$SmartStorage.get("proposalId"),
                    orderId: this.$SmartStorage.get("orderId"),
                    itemIds: [...this.venueList, ...this.mealList, ...this.accommodationList, ...this.otherList].map(ele => ele.itemId),
                    itsSuppliersV2: venueList
                }
                let response = await this.$service.StartInquiry(params);
                if (response.success) {
                    this.$toast("需求提交询价成功！");
                    this.closeWebview()
                }
            }).catch(() => {});
        }
    }
}
